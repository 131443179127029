import React, { useContext, useEffect, useState } from 'react'
import { Card, FormControlLabel, RadioGroup, Radio, Grid, CardContent, FormControl, Avatar, Typography, CardActionArea, CircularProgress } from '@material-ui/core'
import { AttachMoneyOutlined, PeopleOutlined, PaymentOutlined, MoneyOutlined } from '@material-ui/icons'
import I from 'immutable'
import Context from 'reactContext'
import Image from 'components/Image'
import loadable from '@loadable/component'
import Agent from 'components/agent'
import { post, get } from 'utils/request'

const AgentTotalChargePieChart = loadable(() => import('components/AgentTotalCharge'), { ssr: false })

const Marketing = () => {
  const { payments, selfSet, infoSet, paymentsSet, info, users, self } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [chargeAmount, setChargeAmount] = useState(500)
  const recharge = async (amount, channel) => {
    const res = await post('agents/payments/recharge', { amount, channel })
    if (res.ok) {
      window.location = res.url
    }
  }

  const paymentsIndex = async () => {
    const res = await get('agents/payments')
    if (res.ok) {
      paymentsSet(I.fromJS(res.payments))
    }
  }

  const getInitialData = async () => {
    const res = await get('agents/initial_data')
    if (res.ok) {
      selfSet(I.fromJS(res.self))
      infoSet(I.fromJS(res.info || {}))
    }
  }

  const onPay = (channel) => {
    recharge(Math.round(chargeAmount * 100), channel)
  }

  const ListItem = () => {
    return (
      <div
        role="presentation"
        onClick={() => {
          setLoading(true)
          onPay('stripe_alipay')
        }}
        className="cursor-pointer"
      >
        {loading ? (
          <div className="text-center">
            <CircularProgress size={30} />
          </div>
        ) : (
          <span className="qiyetong">
            <div className="qiyetong-payment mt-6 w-48">
              <Image className="qiyetong-pay" fileName="alipay.png" alt="" />
            </div>
          </span>
        )}
      </div>
    )
  }

  const paymentsPanel = () => {
    return (
      <Card style={{ flex: '0 0 100%' }}>
        <CardContent>
          <div className="font-bold text-base">支付方式</div>
          <ListItem />
        </CardContent>
      </Card>
    )
  }

  const quickChargePanel = () => {
    return (
      <FormControl>
        <RadioGroup row value={chargeAmount}>
          {[300, 500, 1000, 2000, 5000, 10000].map((v) => (
            <FormControlLabel key={v} value={v} onChange={() => setChargeAmount(v)} control={<Radio />} label={`¥${v}`} />
          ))}
        </RadioGroup>
      </FormControl>
    )
  }

  const usersData = []

  payments
    .filter((u) => u.get('state') === 'succeeded')
    .forEach((d) => {
      usersData.push({
        enterprise_name: users.getIn([d.get('user_id'), 'settings', 'enterprise', 'name'], self.get('mobile') || self.get('email')),
        amount: d.get('amount') / 100,
      })
    })

  const accountSections = () => {
    const usersNumber = info.get('usersNumber', 0)
    const totalPayments = self.get('total_charge', 0) / 100
    const balance = self.get('balance', 0) / 100
    const totalBonus = self.get('sales_total_bonus', 0) / 100
    const cardProps = [
      [
        '公司数量',
        <Avatar className="h-12 w-12 float-right -mt-12 text-white bg-green-400">
          <PeopleOutlined />
        </Avatar>,
        usersNumber,
      ],
      [
        '支付总额',
        <Avatar className="h-12 w-12 float-right -mt-12 text-white bg-red-400">
          <MoneyOutlined />
        </Avatar>,
        `¥ ${totalPayments}`,
      ],
      [
        '剩余金额',
        <Avatar className="h-12 w-12 float-right -mt-12 text-white bg-orange-400">
          <PaymentOutlined />
        </Avatar>,
        `¥ ${balance}`,
      ],
      [
        '返利总额',
        <Avatar className="h-12 w-12 float-right -mt-12 text-white bg-blue-400">
          <AttachMoneyOutlined />
        </Avatar>,
        `¥ ${totalBonus}`,
      ],
    ]

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} className="flex">
            <Card style={{ flex: '0 0 100%' }}>
              <CardContent>
                <div className="text-base font-bold mb-6">企业充值</div>
                {quickChargePanel()}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={5} className="flex">
            {paymentsPanel()}
          </Grid>
        </Grid>
        <div className="py-3">
          <Grid container spacing={2}>
            {cardProps.map(([title, icon, cardText]) => (
              <Grid item key={title} xs={12} sm={6} lg={3}>
                <CardActionArea>
                  <Card className="cursor-pointer">
                    <CardContent className="p-6">
                      <Typography className="text-base">{title}</Typography>
                      <Typography className="text-xl mt-3 font-bold">{cardText}</Typography>
                      {icon}
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    )
  }

  useEffect(() => {
    getInitialData()
    paymentsIndex()
  }, [])

  return (
    <Agent>
      {accountSections()}
      <div className="font-bold text-base my-3">支付数据图</div>
      <AgentTotalChargePieChart data={usersData} />
    </Agent>
  )
}

export default Marketing
